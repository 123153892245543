<template>
  <div class="user-manage-container">
    <el-card class="header">
      <el-radio-group v-model="lanmuRadio">
        <el-radio
          v-for="item in lanmuRadioOption"
          :key="item.value"
          :label="item.value"
        >
          {{ item.label }}
        </el-radio>
      </el-radio-group>
    </el-card>

    <el-card>
      <div class="news-create-container">
        <el-form
          ref="ruleFormRef"
          style="flex: 2"
          :model="shareInfo"
          :rules="formRules"
          label-width="120px"
        >
          <el-form-item label="启用分享" prop="isShare" required>
            <el-radio-group v-model="shareInfo.isShare">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="分享页背景图" prop="imageUrl" required>
            <UploadImage
              v-model="shareInfo.imageUrl"
              width="200px"
              height="200px"
              format="array"
            ></UploadImage>
          </el-form-item>
        </el-form>
      </div>

      <el-form :model="shareInfo" label-width="120px">
        <el-form-item>
          <el-button @click="resetShare">重置</el-button>
          <el-button type="primary" :loading="submitLoading" @click="onSubmit"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script setup>
import { ref, onActivated, watch, computed } from 'vue'
import * as sysApi from '@/api/sys.js'
import { rules } from './data.js'
import UploadImage from '@/components/UploadImage/index.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import _ from 'lodash'

// 数据相关
const loading = ref(false)

const lanmuRadio = ref(1)
const lanmuRadioOption = [
  {
    label: '分享',
    value: 1
  }
]
const currentLanmu = computed(() => {
  const index = lanmuRadioOption.findIndex(
    item => item.value === lanmuRadio.value
  )
  return lanmuRadioOption[index].label
})
watch(
  () => lanmuRadio.value,
  val => {
    getListData()
  }
)

// 获取数据的方法
const formRules = rules
const getListData = () => {
  if (lanmuRadio.value === 1) {
    getshare()
    getshareImg()
  }
}
// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

const shareInfo = ref({
  id: '',
  isShare: '',
  imageUrl: []
})
const getshare = async () => {
  loading.value = true
  const result = await sysApi.getshare()
  shareInfo.value = result
  loading.value = false
}

// 配置小程序分享页背景图
const shareImgInfo = ref({})
const getshareImg = async () => {
  const result = await sysApi.getshareImg(1)
  shareImgInfo.value = result
  shareInfo.value.imageUrl = [{ img: result.imageUrl, ysImg: result.imageUrl }]
}

// 提交事件
const submitLoading = ref(false)
const ruleFormRef = ref(null)
const onSubmit = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (!valid) {
      ElMessage.error(fields[Object.keys(fields)[0]][0].message)
      return
    }

    submitLoading.value = true
    try {
      await sysApi.updateshare(_.pick(shareInfo.value, ['id', 'isShare']))
      await sysApi.updateShareImg({
        id: 1,
        imageUrl: _.pick(shareInfo.value, ['imageUrl']).imageUrl[0].img
      })
      ElMessage.success('更新成功')
    } finally {
      submitLoading.value = false
    }
  })
}
const resetShare = () => {
  getshare()
}
</script>

<style lang="scss" scoped>
.user-manage-container {
  .header {
    margin-bottom: 22px;
    text-align: left;
  }
  ::v-deep .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  ::v-deep .el-tag {
    margin-right: 6px;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}

.table-head-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
  .button {
    margin: 0 5px;
  }
}
</style>
